import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const Register = lazy(() => import('./view/register'));
const AuthDelivery = lazy(() => import('./view/delivery/auth'));
const HomeDelivery = lazy(() => import('./view/delivery/newOrders'));
const AcceptedDelivery = lazy(() => import('./view/delivery/acceptedOrders'));
const MyAccountDelivery = lazy(() => import('./view/delivery/myAccount'));

const AppRoutes = () => (
    <Router>
        <Suspense fallback={<div className="flex flex-col items-center justify-center h-screen"><CircularProgress size={60} /></div>}>
            <Routes>
                <Route exact path="/" element={<AuthDelivery />} />
                <Route exact path="home-delivery" element={<HomeDelivery />} />
                <Route exact path="accepted-delivery" element={<AcceptedDelivery />} />
                <Route exact path="myaccount-delivery" element={<MyAccountDelivery />} />
                <Route exact path="register" element={<Register />} />
            </Routes>
        </Suspense>
    </Router>
);

export default AppRoutes;