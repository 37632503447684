import { combineReducers } from 'redux';
import loadingReducer from './loading.reducer';
import notifyReducer from './notify.reducer';
import alertReducer from './alert.reducer';
import authReducer from './auth.reducer';
import registerReducer from './register.reducer';
import notificationReducer from './notification.reducer';
import userReducer from './user.reducer';
import deliveryReducer from './delivery.reducer';

const rootReducer = combineReducers({
    loadingReducer,
    notifyReducer,
    alertReducer,
    authReducer, 
    registerReducer,
    notificationReducer,
    userReducer,
    deliveryReducer,
})

export default rootReducer;